<template>
  <div>
    <div class="page-container md-layout-column">
      <div class="container-fluid px-0 view-home-maquillate">
        <nav-bar-normal
          :bg-color="this.bgColor"
          :get-profile="getProfile"
          :limit-categories="this.limitCategories"
          :show-navbar="this.showNavbar"
          :show-navigation="this.showNavigation"
          :show-search-mobile="this.showSearchMobile"
        ></nav-bar-normal>
        <!-- </div> -->
        <!-- <div id="app"> -->
        <nav-bar-mobile
          :bg-color="this.bgColor"
          :get-profile="getProfile"
          :limit-categories="this.limitCategories"
          :show-navbar="this.showNavbar"
          :show-search-mobile="this.showSearchMobile"
        ></nav-bar-mobile>
      </div>

      <div>
        <router-view></router-view>
      </div>

      <div class="view-home__part7 footer-mobile pt-5">
        <footer-home :limit-categories="this.limitCategories"></footer-home>
      </div>
      <div class="view-home__part7 footer-desk pt-5">
        <footer-in-show-course
          :limit-categories="this.limitCategories"
        ></footer-in-show-course>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import NavBarMobile from './components/header/navBarMobile.vue';
import NavBarNormal from './components/header/navBarNormal.vue';
import FooterHome from './components/footer/FooterHome.vue';
import FooterInShowCourse from './components/footer/FooterInShowCourse.vue';

export default {
  components: {
    NavBarMobile,
    NavBarNormal,
    FooterHome,
    FooterInShowCourse,
  },
  data() {
    return {
      limitCategories: null,
      showExplore: false,
      showByCategory: false,
      coursesCategory: null,
      showNavbar: true,
      lastScrollPosition: 0,
      scrollValue: 0,
      showMenu: false,
      menuTransitionDuration: 250,
      pageClass: 'login-page',
      inputSearch: false,
      showCategories: false,
      showNavigation: false,
      showSearchMobile: false,
      bgColor: true,
    };
  },
  computed: {
    ...mapGetters(['getProfile', 'getCartCount']),
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.showNavbar = true;
      },
    },
    getCartCount: function () {
      this.showNavbar = true;
    },
  },
};
</script>

